<template>
  <div>
    <div class="flex flex-row items-center border-b border-gray-200 py-4">
      <h1 class="text-2xl font-bold flex items-center text-blue-600">
        <strong>Clientes</strong>
      </h1>
      <div class="ml-auto flex flex-row space-x-2">
        <sa-button
          type="primary"
          class="font-semibold text-white rounded-lg transition duration-200 ease-in-out"
          @click="upload"
          :disabled="!fileList.length">
          Importar
        </sa-button>
        <sa-button
          type="primary"
          class="font-semibold text-white rounded-lg transition duration-200 ease-in-out"
          @click="download">
          Exportar
        </sa-button>
      </div>
    </div>

    <div class="flex flex-col space-y-6 mt-6">
      <content-card class="p-6 border border-blue-200 bg-white rounded-lg shadow-md" v-loading.fullscreen.lock="loading">
        <div class="w-full text-md font-semibold text-gray-700">
          <slot name="title">
            <div>
              El archivo deberá estar en formato CSV y contener los siguientes campos:
            </div>
            <ul>
              <li>FullName</li>
              <li>Email</li>
              <li>Phone</li>
              <li>Dni</li>
              <li>ContactDescription</li>
            </ul>
            <div class="mt-4 text-right">
              <a
                href="https://s3.amazonaws.com/documents.prod.samiiweb.com/csv/Clientes+csv.csv"
                target="_blank"
                class="text-blue-500 underline hover:text-blue-700"
              >
                Descargar archivo de ejemplo aquí
              </a>
            </div>
          </slot>
        </div>
        
        <el-form :model="form" :rules="rules" ref="myDataForm" v-loading="loading">
          <el-form-item prop="file" class="mt-4">
            <el-upload
              action="null"
              :before-upload="beforeUpload"
              :before-remove="beforeRemove"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :file-list="fileList"
              :limit="1"
              :auto-upload="false"
              accept=".csv"
              class="border-2 border-dashed border-gray-300 rounded-lg p-4 hover:border-blue-400 transition upload-csv justify-items-end"
            >
              <div class="flex flex-row items-center space-y-2">
                <div class="text-gray-500 text-sm mr-4">Seleccione un archivo CSV</div>
                <el-button
                  size="small"
                  type="primary"
                  :disabled="fileList.length === 1"
                  class="bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg transition duration-200"
                  style="margin: 0;"
                >
                  Seleccionar archivo
                </el-button>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </content-card>
    </div>
  </div>
</template>
<style scoped>
.upload-csv {
  border: 1px dashed #cbd5e0;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s ease;
}
.upload-csv:hover {
  border-color: #4299e1;
}
ul {
    list-style: square outside;
    margin-left: 20px;
    padding-left: 0;
}
</style>
<script>
import error from "@/mixins/error";
import AdminService from "@/services/AdminService";
import responsiveSize from "@/mixins/responsiveSize";
import FileService from "@/services/FileService";

export default {
  name: "UploadClientFileForm",
  mixins: [error, responsiveSize],
  components: {
    ContentCard: () => import("@/components/molecules/ContentCard"),
  },
  data() {
    return {
      loading: false,
      fileList: [],
      form: {
        file: undefined,
      },
      rules: {},
      isMobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
    beforeUpload(file) {
      const isCSV = file.type === 'text/csv';
      if (!isCSV) {
        this.$message.error('El archivo seleccionado no es un CSV');
      }
      return isCSV;
    },
    beforeRemove(file) {
      return this.$confirm(`¿Desea eliminar el archivo ${file.name}?`);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    clearState() {
      this.fileList = [];
    },
    async upload() {
      this.loading = true;
      try {
        const data = new FormData();
        data.append("file", this.fileList[0].raw);
        await AdminService.ImportExport.Client.update(data, this.$store.state.business.id);
        this.clearState();
        this.$toastr.s("Clientes importados correctamente");
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      } finally {
        this.loading = false;
      }
    },
    async download() {
      this.loading = true;
      try {
        const url = await AdminService.ImportExport.Client.get(this.$store.state.business.id);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Clientes+csv.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => this.removeFileFromS3(url), 10000);
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      } finally {
        this.loading = false;
      }
    },
    async removeFileFromS3(url) {
      const fileName = `import-export/${url.split('import-export/')[1]}`;
      await FileService.delete({ files: [fileName] });
    },
  },
};
</script>